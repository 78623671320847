import BaseSetting from '@config/BaseSetting';
import Routes from './Routes';

/**
 * Contains web api urls
 * */

class WebClientRoutes extends Routes {
  //
  // routes
  // endpoint defined here
  // format: [url_name]Path
  //

  // ref from backend, eg:
  // https://localhost:3001/en/qbo_sso/1657178937/60d1ebad-fdc1-4e62-9802-969447618c45/pgcHT3jbOBYvSmvHE7X2c3gSE9FNGa5-Z2vJOsV3dsc=
  loginSSOPath(params = {}, format = '') {
    return this.createUrl(
      '/:locale/qbo_sso/:timestamp/:authenticationToken/:signature',
      params,
      format
    );
  }

  homePath(params = {}, format = '') {
    return this.createUrl('/', params, format);
  }

  errorHandlerPath(params = {}, format = '') {
    return this.createUrl('/error-page', params, format);
  }

  loginPath(params = {}, format = '') {
    return this.createUrl('/login', params, format);
  }

  dashboardPath(params = {}, format = '') {
    return this.createUrl('/dashboard', params, format);
  }

  formPagePath(params = {}, format = '') {
    return this.createUrl('/form-page', params, format);
  }

  listingPagePath(params = {}, format = '') {
    return this.createUrl('/listing-page', params, format);
  }

  transferSubresellerPagePath(params = {}, format = '') {
    return this.createUrl('/transfer-partner', params, format);
  }

  recreateBackupAdminPath(params = {}, format = '') {
    return this.createUrl('/recreate-backup-admin', params, format);
  }

  changeBackupAdminPath(params = {}, format = '') {
    return this.createUrl('/change-backup-admin', params, format);
  }

  purgeM365TenantPath(params = {}, format = '') {
    return this.createUrl('/purge-m365-tenant', params, format);
  }

  transferSubscriptionPagePath(params = {}, format = '') {
    return this.createUrl('/initiate-subscription', params, format);
  }

  mailBoxChangePath(params = {}, format = '') {
    return this.createUrl('/mailbox-change', params, format);
  }

  mailBoxChangeDetailsPath(params = {}, format = '') {
    return this.createUrl('/mailbox-change/:id', params, format);
  }

  mailBoxSyncPath(params = {}, format = '') {
    return this.createUrl('/mailbox-change-o365', params, format);
  }

  viewExcludedMailBoxPath(params = {}, format = '') {
    return this.createUrl('/view-excluded-mailbox', params, format);
  }

  viewAutoDiscoveryPath(params = {}, format = '') {
    return this.createUrl('/view-auto-discovery', params, format);
  }

  dataIngestionPath(params = {}, format = '') {
    return this.createUrl('/data-ingestion', params, format);
  }

  dataIngestionListPath(params = {}, format = '') {
    return this.createUrl('/data-ingestion-list', params, format);
  }

  bulkPurgePath(params = {}, format = '') {
    return this.createUrl('/purge-mail-account', params, format);
  }

  bulkPurgeListPath(params = {}, format = '') {
    return this.createUrl('/purge-mail-account-list', params, format);
  }

  purgeExternalAccountPath(params = {}, format = '') {
    return this.createUrl('/purge-external-account', params, format);
  }

  purgeSharedDrivePath(params = {}, format = '') {
    return this.createUrl('/purge-shared-drive', params, format);
  }

  showPartnerListPath(params = {}, format = '') {
    return this.createUrl('/partners-list', params, format);
  }

  showPlansListPath(params = {}, format = '') {
    return this.createUrl('/plans-list', params, format);
  }

  registrationPath(params = {}, format = '') {
    return this.createUrl('/registration', params, format);
  }

  viewDomainBackupsPath(params = {}, format = '') {
    return this.createUrl('/domain-backups', params, format);
  }

  transferPath(params = {}, format = '') {
    return this.createUrl('/transfer-subscription', params, format);
  }

  reportsPagePath(params = {}, format = '') {
    return this.createUrl('/list-reports-page', params, format);
  }

  listReportsPagePath(params = {}, format = '') {
    return this.createUrl('/list-item-reports-page', params, format);
  }

  seatUsageReportsPagePath(params = {}, format = '') {
    return this.createUrl('/seat-usage-reports', params, format);
  }

  showOrganizationListPath(params = {}, format = '') {
    return this.createUrl('/orgnizations-list', params, format);
  }

  resellersListPath(params = {}, format = '') {
    return this.createUrl('/partner-info', params, format);
  }

  organizationInformationPath(params = {}, format = '') {
    return this.createUrl('/organization-info', params, format);
  }

  m365TenantListPath(params = {}, format = '') {
    return this.createUrl('/m365-tenant', params, format);
  }

  m365TenantDetailPath(params = {}, format = '') {
    return this.createUrl('/m365-tenant-detail', params, format);
  }

  hexTenantDetailPath(params = {}, format = '') {
    return this.createUrl('/hex-tenant-details', params, format);
  }

  gwsTenantListPath(params = {}, format = '') {
    return this.createUrl('/gws-tenant', params, format);
  }

  gwsTenantDetailPath(params = {}, format = '') {
    return this.createUrl('/gws-tenant-detail', params, format);
  }

  hexTenantListPath(params = {}, format = '') {
    return this.createUrl('/hex-tenant', params, format);
  }

  numberOfUsersListPath(params = {}, format = '') {
    return this.createUrl('/number-of-users-list', params, format);
  }

  numberOfSubResellersListPath(params = {}, format = '') {
    return this.createUrl('/number-of-sub-resellers-list', params, format);
  }

  numberOfOrganizationListPath(params = {}, format = '') {
    return this.createUrl('/number-of-organization-list', params, format);
  }

  showUsersListPath(params = {}, format = '') {
    return this.createUrl('/show-users-list', params, format);
  }

  seatCountChangePath(params = {}, format = '') {
    return this.createUrl('/seat-count-change', params, format);
  }

  monthlyBackupReportsPagePath(params = {}, format = '') {
    return this.createUrl('/monthly-backup-reports', params, format);
  }

  viewTenantAuthErrorsPath(params = {}, format = '') {
    return this.createUrl('/view-tenant-auth-errors', params, format);
  }

  switchSPATypePath(params = {}, format = '') {
    return this.createUrl('/switch-spa-type', params, format);
  }

  purgeGWSTenantPath(params = {}, format = '') {
    return this.createUrl('/purge-gws-tenant', params, format);
  }

  userBackupInfoPath(params = {}, format = '') {
    return this.createUrl('/backups', params, format);
  }

  userBackupDetailPath(params = {}, format = '') {
    return this.createUrl('/backup-detail', params, format);
  }

  backupStatusesPath(params = {}, format = '') {
    return this.createUrl('/backup-statuses', params, format);
  }

  switchCredentialsPath(params = {}, format = '') {
    return this.createUrl('/switch-credential', params, format);
  }

  switchCredentialsDetailPath(params = {}, format = '') {
    return this.createUrl('/switch-credential/:id', params, format);
  }

  showOrganizationUsersListPath(params = {}, format = '') {
    return this.createUrl('/organization-users-list', params, format);
  }

  toolsRequestStatusPath(params = {}, format = '') {
    return this.createUrl('/tools-request-status', params, format);
  }
}

const ClientRoutes = new WebClientRoutes(BaseSetting.internalToolClientHost, true);

export const loginSSOPath = ClientRoutes.loginSSOPath();
export const homePath = ClientRoutes.homePath();
export const errorHandlerPath = ClientRoutes.errorHandlerPath();
export const loginPath = ClientRoutes.loginPath();
export const dashboardPath = ClientRoutes.dashboardPath();
export const formPagePath = ClientRoutes.formPagePath();
export const listingPagePath = ClientRoutes.listingPagePath();
export const transferSubresellerPagePath = ClientRoutes.transferSubresellerPagePath();
export const transferSubscriptionPagePath = ClientRoutes.transferSubscriptionPagePath();
export const mailBoxChangePath = ClientRoutes.mailBoxChangePath();
export const mailBoxChangeDetailsPath = ClientRoutes.mailBoxChangeDetailsPath();
export const mailBoxSyncPath = ClientRoutes.mailBoxSyncPath();
export const viewExcludedMailBoxPath = ClientRoutes.viewExcludedMailBoxPath();
export const viewAutoDiscoveryPath = ClientRoutes.viewAutoDiscoveryPath();
export const dataIngestionPath = ClientRoutes.dataIngestionPath();
export const dataIngestionListPath = ClientRoutes.dataIngestionListPath();
export const bulkPurgePath = ClientRoutes.bulkPurgePath();
export const bulkPurgeListPath = ClientRoutes.bulkPurgeListPath();
export const registrationPath = ClientRoutes.registrationPath();
export const domainBackupsPath = ClientRoutes.viewDomainBackupsPath();
export const transferPath = ClientRoutes.transferPath();
export const reportsPagePath = ClientRoutes.reportsPagePath();
export const listReportsPagePath = ClientRoutes.listReportsPagePath();
export const seatUsageReportsPagePath = ClientRoutes.seatUsageReportsPagePath();
export const purgeExternalAccountPath = ClientRoutes.purgeExternalAccountPath();
export const purgeSharedDrivePath = ClientRoutes.purgeSharedDrivePath();
export const showPartnerListPath = ClientRoutes.showPartnerListPath();
export const resellersListPath = ClientRoutes.resellersListPath();
export const numberOfUsersListPath = ClientRoutes.numberOfUsersListPath();
export const numberOfSubResellersListPath = ClientRoutes.numberOfSubResellersListPath();
export const numberOfOrganizationListPath = ClientRoutes.numberOfOrganizationListPath();
export const showOrganizationListPath = ClientRoutes.showOrganizationListPath();
export const showPlansListPath = ClientRoutes.showPlansListPath();
export const showUsersListPath = ClientRoutes.showUsersListPath();
export const seatCountChangePath = ClientRoutes.seatCountChangePath();
export const monthlyBackupReportsPagePath = ClientRoutes.monthlyBackupReportsPagePath();
export const viewTenantAuthErrorsPath = ClientRoutes.viewTenantAuthErrorsPath();
export const organizationInformationPath = ClientRoutes.organizationInformationPath();
export const switchSPATypePath = ClientRoutes.switchSPATypePath();
export const m365TenantListPath = ClientRoutes.m365TenantListPath();
export const m365TenantDetailPath = ClientRoutes.m365TenantDetailPath();
export const gwsTenantDetailPath = ClientRoutes.gwsTenantDetailPath();
export const gwsTenantListPath = ClientRoutes.gwsTenantListPath();
export const recreateBackupAdminPath = ClientRoutes.recreateBackupAdminPath();
export const purgeM365TenantPath = ClientRoutes.purgeM365TenantPath();
export const userBackupInfoPath = ClientRoutes.userBackupInfoPath();
export const purgeGWSTenantPath = ClientRoutes.purgeGWSTenantPath();
export const userBackupDetailPath = ClientRoutes.userBackupDetailPath();
export const hexTenantListPath = ClientRoutes.hexTenantListPath();
export const changeBackupAdminPath = ClientRoutes.changeBackupAdminPath();
export const hexTenantDetailPath = ClientRoutes.hexTenantDetailPath();
export const switchCredentialsPath = ClientRoutes.switchCredentialsPath();
export const switchCredentialsDetailPath = ClientRoutes.switchCredentialsDetailPath();
export const showOrganizationUsersListPath = ClientRoutes.showOrganizationUsersListPath();
export const toolsRequestStatusPath = ClientRoutes.toolsRequestStatusPath();
export const backupStatusesPath = ClientRoutes.backupStatusesPath();

export const publicLayouts = [loginPath, loginSSOPath];

export default ClientRoutes;
