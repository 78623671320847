import { constant } from '@config/BaseSetting';
import {
  getPartnersListAsync,
  resetPartnersListState,
} from '@features/partnerList/partnersListSlice';
import { getPartnersFilterListAsync } from '@features/partnerList/partnersFilterListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import useFeatureFlag from '@hooks/useFeatureFlag';
import { useTranslation } from 'react-i18next';
import { showFixedAlert } from '@features/application/applicationSlice';

export default function usePartnerListService(
  selected,
  setSelected,
  names,
  setFilterdState,
  setSearchState,
  setAdvanceClick,
  setForceUpdate,
  advanceClick,
  setSelectedColumn,
  setSortedData,
  setSelectStatus,
  setIntegrationTypeState,
  setSelectSalesOwner,
  setSearchByID,
  setFullTextSearch,
  setSearchFieldValue,
  setSearchTerm,
  setSelectedPartner,
  searchTerm
) {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleFetchPartnersListAsync = (
    paginationList,
    columnSelected,
    selectedArray,
    integrationState,
    salesOwner,
    searchID,
    SearchText,
    selectStatus,
    partnerName
  ) => {
    setSortedData([]);
    dispatch(
      getPartnersListAsync({
        accessToken,
        reset: true,
        ...{
          searchById: searchID || 0,
          fullTextSearch: SearchText,
          partnerType: selectedArray,
          status: selectStatus,
          pageNumber: paginationList.pageIndex + 1,
          pageSize: paginationList.pageSize,
          integrationType: integrationState,
          orderBy: columnSelected,
          salesOwner,
          partnerName,
        },
      })
    );
  };

  const { getPartnersListState } = useSelector((state) => state.partnersList);
  const { fetching, partnersList } = getPartnersListState;
  const [toggleShowBulkCreateOrganizationModal, setToggleShowBulkCreateOrganizationModal] =
    useState(false);
  const [selectedPartnerCommand, setSelectedPartnerCommand] = useState(null);
  const { t } = useTranslation();
  const { featureFlags, hasPermission } = useFeatureFlag();

  const handleCheckBoxChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === names.length ? [] : names);
      return;
    }
    setSelected(value);
  };

  const handlePartnerFilterSearch = (search) => {
    dispatch(
      getPartnersFilterListAsync({
        accessToken,
        reset: true,
        ...{ searchTerm: search, isOnlyParent: true },
      })
    );
  };

  const handleOnSearchChange = (event) => {
    const searchedResult = event.target.value;
    setSearchState(searchedResult);

    const filterdValues = names.filter((name) =>
      name.toLowerCase().includes(searchedResult.toLowerCase())
    );

    setFilterdState(filterdValues);
  };

  const handleCloseClick = () => {
    setSearchState('');
    setForceUpdate(true);
  };

  const handleOnClose = () => {
    // setSelected([]);
    // setAge('');
  };

  const handleOnCloseClick = () => {
    setSearchTerm('');
    setSelectedPartner('');
    handlePartnerFilterSearch(searchTerm);
  };

  const handleOnAdvanceSearchClick = () => {
    setAdvanceClick(!advanceClick);

    if (!advanceClick) {
      handleOnClose();
    }
  };

  const handleChange = (event) => {
    setSelectSalesOwner(event.target.value);
  };

  const handleOnSortByChange = (event) => {
    setSelectedColumn(String(event.target.value));
  };

  const handleStatusChange = (e) => {
    setSelectStatus(e.target.value);
  };

  const handlePartnerNameChange = (e) => {
    setSelectedPartner(e.target.value);
  };

  const matchingText = (
    searchId,
    searchText,
    checKBoxData,
    statusData,
    selectSalesOwner,
    setMatchText,
    integrationType
  ) => {
    if (
      !searchText &&
      !searchId &&
      !statusData &&
      checKBoxData.length === 0 &&
      !integrationType &&
      !selectSalesOwner
    ) {
      return '';
    }

    let textData = 'Matching';

    if (searchId) {
      textData += ` ID = ${searchId}`;
    }

    if (searchText) {
      textData += ` email/name like '%${searchText}%' `;
    }

    if (checKBoxData && checKBoxData.length > 0) {
      textData += ' Partner Type = ';
      textData += checKBoxData.reduce((acc, val, index) => {
        return acc + (index === 0 ? '' : ', ') + val;
      }, '');
    }

    if (statusData) {
      if (checKBoxData && checKBoxData.length > 0) {
        textData += ', ';
      }
      textData += ` Status = ${statusData}`;
    }

    if (integrationType) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Integration Type = ${integrationType}`;
    }

    if (selectSalesOwner) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Sales Owner = ${selectSalesOwner}`;
    }

    setMatchText(textData);
    return textData;
  };

  const handleIntegrationChange = (e) => {
    setIntegrationTypeState(e.target.value);
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setSearchFieldValue(input);
    if (/^\d+$/.test(input)) {
      setSearchByID(input);
      setFullTextSearch(input);
    } else {
      setSearchByID('');
      setFullTextSearch(input);
    }
  };

  const showBulkCreateOrganizationModal = (row) => {
    const partner = row.original;
    if (partner.deletedAt) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('partner_list_page.bulk_create_organization.validations.deleted'),
          severity: 'error',
        })
      );
    } else {
      setToggleShowBulkCreateOrganizationModal(true);
      setSelectedPartnerCommand(partner);
    }
  };

  const menuActions = ({ row, closeMenu }) => {
    const i = 1;
    const items = [];
    if (hasPermission(featureFlags.BulkCreateOrganization)) {
      items.push(
        <MenuItem
          key={i}
          onClick={() => {
            showBulkCreateOrganizationModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('partner_list_page.bulk_create_organization.title')}
        </MenuItem>
      );
    }
    return items;
  };

  const hasMenuActions = hasPermission(featureFlags.BulkCreateOrganization);

  const frozenColumn = hasMenuActions ? ['mrt-row-actions', 'id', 'name'] : ['id', 'name'];

  return {
    handleCheckBoxChange,
    handleOnSearchChange,
    handleCloseClick,
    handleOnAdvanceSearchClick,
    handleChange,
    handleOnSortByChange,
    handleFetchPartnersListAsync,
    handleStatusChange,
    matchingText,
    handleIntegrationChange,
    handleOnTextFiledChange,
    handlePartnerFilterSearch,
    handleOnCloseClick,
    handlePartnerNameChange,
    hasMenuActions,
    menuActions,
    selectedPartnerCommand,
    toggleShowBulkCreateOrganizationModal,
    setToggleShowBulkCreateOrganizationModal,
    frozenColumn,
  };
}
