import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';
import axios from 'axios';
import { saveAs } from 'file-saver';

const webClientApi = (accessToken, reset) => {
  return new ServiceClientFactory({
    baseUrl: WebApiRoutes.getBaseUrl(),
    accessToken,
    reset,
  }).create();
};

const TenantService = {
  getRecreateBackupAdminsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getRecreateBackupAdminsListPath({ pageNumber, pageSize }),
    });
  },

  recreateBackupAdminsAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createRecreateBackupAdminsPath(),
      parameters,
    });
  },

  runLicenseCheckerAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createRunLicenseCheckerPath(),
      parameters,
    });
  },

  runAutodiscoverAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createRunAutodiscoverPath(),
      parameters,
    });
  },

  invalidateServiceTokenAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.invalidateServiceTokenPath(),
      parameters,
    });
  },

  tenantDetailAPI: (paramsList) => {
    const { accessToken, parameters } = paramsList;

    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.tenantDetailPath(parameters),
      parameters,
    });
  },

  getPurgeM365TenantsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getPurgeM365TenantsListPath({ pageNumber, pageSize }),
    });
  },

  getChangeBackupAdminsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getChangeBackupAdminsPath({ pageNumber, pageSize }),
    });
  },

  purgeM365TenantAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createPurgeM365TenantPath(),
      parameters,
    });
  },

  changeBackupAdminAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createChangeBackupAdminsPath(),
      parameters,
    });
  },

  validateChangeBackupAdminAPI: (paramsList) => {
    const { accessToken, parameters } = paramsList;

    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.validateChangeBackupAdminPath(parameters),
      parameters,
    });
  },

  gwsTenantDetailAPI: (paramsList) => {
    const { accessToken, parameters } = paramsList;

    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.gwsTenantDetailPath(parameters),
      parameters,
    });
  },

  getPurgeGWSTenantsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getPurgeGWSTenantsListPath({ pageNumber, pageSize }),
    });
  },

  purgeGWSTenantAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createPurgeGWSTenantPath(),
      parameters,
    });
  },

  getGwsUsersTenantAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      lastRequestId,
      pageNumber,
      pageSize,
      fullTextSearch,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.getGwsUsersTenantPath({ pageNumber, pageSize, lastRequestId }),
      parameters: {
        'full-text-search': fullTextSearch,
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  tenantSizeReportAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createTenantSizeReportPath(),
      parameters,
    });
  },

  exportTenantSizeReportAPI: (paramsList) => {
    const { accessToken, reset, workerId } = paramsList;
    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.getExportTenantSizeReportPath({ workerId }),
      config: { responseType: 'arraybuffer' },
    });

    return response;
  },
};

export default TenantService;
