export const SUMMARY_HEADERS = [
  { id: 1, label: 'In Progress', columnName: 'in-progress-count', sx: { paddingLeft: 2 } },
  { id: 2, label: 'Success', columnName: 'success-count', sx: { paddingLeft: 2 } },
  { id: 3, label: 'Error', columnName: 'error-count', sx: { paddingLeft: 2 } },
];
export const BULK_CREATE_ORGANIZATION_ITEMS_HEADERS = [
  { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
  { id: 2, label: 'Email', columnName: 'email', sx: { paddingLeft: 2 } },
  { id: 3, label: 'First Name', columnName: 'first-name', sx: { paddingLeft: 2 } },
  { id: 4, label: 'Last Name', columnName: 'last-name', sx: { paddingLeft: 2 } },
  {
    id: 5,
    label: 'Organization Name',
    columnName: 'organization-name',
    sx: { paddingLeft: 2 },
  },
  { id: 6, label: 'Seats', columnName: 'seats', sx: { paddingLeft: 2 } },
  { id: 7, label: 'Auto License', columnName: 'auto-license', sx: { paddingLeft: 2 } },
  { id: 8, label: 'Plan', columnName: 'plan', sx: { paddingLeft: 2 } },
  { id: 9, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 } },
  {
    id: 10,
    label: 'Status Description',
    columnName: 'status-description',
    sx: { paddingLeft: 2 },
    size: 360,
  },
  {
    id: 11,
    label: 'Last Updated On',
    columnName: 'last-updated-on',
    sx: { paddingLeft: 2 },
    size: 240,
  },
  { id: 12, label: 'Retry Count', columnName: 'retry-count', sx: { paddingLeft: 2 } },
  {
    id: 13,
    label: 'Last Retried On',
    columnName: 'last-retried-on',
    sx: { paddingLeft: 2 },
    size: 240,
  },
];
