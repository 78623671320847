import React from 'react';
import { QboModal, ITBox, QboFormGroup, QboTextField } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import useRunLicenseChecker from '@pages/TenantPage/M365Tenant/pagehooks/useRunLicenseChecker';
import useInvalidateServiceToken from '@pages/TenantPage/M365Tenant/pagehooks/useInvalidateServiceToken';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useRunAutodiscover from '@pages/TenantPage/M365Tenant/pagehooks/useRunAutodiscover';
import useTenantSizeReport from '@pages/TenantPage/M365Tenant/pagehooks/useTenantSizeReport';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { EMAIL } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import { DRIVE } from '@pages/UserBackupPage/pageHooks/constants/driveConstants';

export default function RequestModalTool({
  children,
  closeSetupModal,
  showRequestModal,
  topBarElement,
  title,
  selectedCredential,
  buttonDisabled,
  serviceType,
  selectedTenant,
}) {
  const { featureFlags } = useFeatureFlag();
  const { t } = useTranslation();
  const isAstericRequired = true;
  let objectState = {};
  let hookCommand = () => {};

  if (featureFlags.RunLicenseChecker === serviceType) {
    hookCommand = useRunLicenseChecker({
      selectedCredential,
      onSuccessRequest: closeSetupModal,
    });

    objectState = hookCommand.runLicenseCheckerState;
  }

  if (featureFlags.InvalidateServiceToken === serviceType) {
    hookCommand = useInvalidateServiceToken({
      selectedCredential,
      onSuccessRequest: closeSetupModal,
    });

    objectState = hookCommand.invalidateServiceTokenState;
  }

  if (featureFlags.RunAutodiscover === serviceType) {
    hookCommand = useRunAutodiscover({
      selectedCredential,
      onSuccessRequest: closeSetupModal,
    });

    objectState = hookCommand.runAutodiscoverState;
  }

  if (featureFlags.TenantSizeReport === serviceType) {
    hookCommand = useTenantSizeReport({
      selectedCredential,
      selectedTenant,
      onSuccessRequest: closeSetupModal,
    });

    objectState = hookCommand.tenantSizeReportState;
  }

  const { form, onValidateData } = hookCommand;

  const closeModal = () => {
    closeSetupModal();
    form.resetForm();
  };

  const reportTypeSelection = () => {
    if (featureFlags.TenantSizeReport !== serviceType) return null;

    return (
      <QboSortByButton
        defaultValue={hookCommand.reportType}
        marginRightValue={2}
        marginButtonValue={1}
        value={hookCommand.reportType}
        buttonWidth={220}
        startAdornmentTextWidth={200}
        startAdornmentText="Report Type"
        handleChange={(e) => {
          hookCommand.setReportType(e.target.value);
        }}
        menuItemList={[EMAIL]}
      />
    );
  };

  return (
    <QboModal
      onClose={closeModal}
      open={showRequestModal}
      title={title}
      showCloseIcon
      preventOutsideClick>
      <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
        <QboFormGroup
          loadingButton={objectState.fetching}
          onSubmit={onValidateData}
          withGenerateAndResetButton
          handleOnResetButton={closeModal}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit"
          buttonDisabled={buttonDisabled}>
          {topBarElement}
          {children}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                required={isAstericRequired}
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                value={form.attributes.supportTicketAttributes.value}
                errorMessage={form.attributes.supportTicketAttributes.errorMessage}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.attributes.supportTicketAttributes.handleChange(e.currentTarget.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.attributes.escalationTicketAttributes.value}
                onChange={(e) =>
                  form.attributes.escalationTicketAttributes.handleChange(e.currentTarget.value)
                }
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.attributes.descriptionAttributes.value}
            onChange={(e) =>
              form.attributes.descriptionAttributes.handleChange(e.currentTarget.value)
            }
            inputProps={{ maxLength: 225 }}
          />
          {reportTypeSelection()}
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

RequestModalTool.propTypes = {
  closeSetupModal: PropTypes.func,
  showRequestModal: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  topBarElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  selectedCredential: PropTypes.object,
  buttonDisabled: PropTypes.bool,
  serviceType: PropTypes.string,
  selectedTenant: PropTypes.object,
};

RequestModalTool.defaultProps = {
  closeSetupModal: () => {},
  showRequestModal: false,
  title: '',
  children: '',
  topBarElement: '',
  selectedCredential: null,
  buttonDisabled: false,
  serviceType: '',
  selectedTenant: null,
};
