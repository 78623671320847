import { constant } from '@config/BaseSetting';
import {
  getBulkCreateOrganizationTemplateAsync,
  resetGetBulkCreateOrganizationTemplateState,
} from '@features/bulkCreateOrganization/getBulkCreateOrganizationTemplateSlice';
import useDownload from '@hooks/useDownload';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useLocalStorage from '@hooks/useLocalStorage';
import { QboButton, QboCard, QboSnackbar, QboTypography } from '@ui/Components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export default function TemplatesSection() {
  const { hasPermission, featureFlags } = useFeatureFlag();
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { t } = useTranslation();
  const { getBulkCreateOrganizationTemplateState } = useSelector(
    (state) => state.getBulkCreateOrganizationTemplate
  );

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleDownloadBulkCreateOrganizationTemplate = () => {
    dispatch(
      getBulkCreateOrganizationTemplateAsync({
        accessToken,
        reset: true,
      })
    );
  };

  const handleResetExport = () => {
    dispatch(resetGetBulkCreateOrganizationTemplateState());
  };

  useDownload(
    getBulkCreateOrganizationTemplateState.exportData,
    'text/csv;charset=utf-8',
    'bulk_create_organization_template.csv',
    handleResetExport,
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  if (!hasPermission(featureFlags.BulkCreateOrganization)) return null;

  return (
    <>
      <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
      <QboCard isMainWrapper sx={{ paddingBottom: '20px' }}>
        <QboTypography
          sx={{
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
            marginBottom: '8px',
          }}>
          Templates
        </QboTypography>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {hasPermission(featureFlags.BulkCreateOrganization) && (
            <QboButton
              variant="contained"
              onClick={handleDownloadBulkCreateOrganizationTemplate}
              loading={getBulkCreateOrganizationTemplateState.fetching}>
              {t('partner_list_page.bulk_create_organization.title')}
            </QboButton>
          )}
        </div>
      </QboCard>
    </>
  );
}
