import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboAdvanceTable,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { setPartnersPaginationState } from '@features/application/applicationSlice';
import { resetPartnersListState } from '@features/partnerList/partnersListSlice';
import QboSortByButton from '@ui/Components/QboSortByButton';
import BulkCreateOrganizationModal from '@ui/Sections/PartnerListPage/BulkCreateOrganizationModal';
import BulkCreateOrganizationConfirmation from '@ui/Sections/PartnerListPage/BulkCreateOrganizationConfirmation';
import TemplatesSection from '@ui/Sections/PartnerListPage/TemplatesSection';
import usePartnerListService from './pageHooks/usePartnerListService';
import useDataService from './pageHooks/useDataService';

export default function PartnerListPage() {
  const { t } = useTranslation();
  const { paginationState } = useSelector((state) => state.application);
  const { partnersPaginations } = paginationState;
  const [pagination, setPagination] = useState({
    pageIndex: partnersPaginations?.pageIndex || 0,
    pageSize: partnersPaginations?.pageSize || 10,
  });

  const [integrationTypeState, setIntegrationTypeState] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [searchByID, setSearchByID] = useState('');
  const [fullTextSearch, setFullTextSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [selectStatus, setSelectStatus] = useState('');
  const [advanceClick, setAdvanceClick] = useState(false);
  const [searchState, setSearchState] = useState('');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectSalesOwner, setSelectSalesOwner] = useState('');
  const [resetClicked, setResetClicked] = useState(false);
  const [matchText, setMatchText] = useState('');
  const [partnerNames, setPartnerNames] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');
  const dispatch = useDispatch();

  const { getPartnersListState } = useSelector((state) => state.partnersList);
  const { partnersList, fetching, paginationList, statusCode } = getPartnersListState;
  const { getPartnersFilterListState } = useSelector((state) => state.partnersFilterList);
  const { partnersFilterList } = getPartnersFilterListState;
  const {
    names,
    statusMenuItems,
    salesOwnersList,
    columnNames,
    buttonStyle,
    integrationTypes,
    clickableCoulmnList,
  } = useDataService();

  const [filterdState, setFilterdState] = useState(names);

  const [selected, setSelected] = useState([]);
  const isAllSelected = names.length > 0 && selected.length === names.length;

  useEffect(() => {
    if (!searchState) {
      setForceUpdate(false);
    }
  }, [searchState]);

  const {
    handleCheckBoxChange,
    handleOnSearchChange,
    handleCloseClick,
    handleOnAdvanceSearchClick,
    handleChange,
    handleOnSortByChange,
    handleFetchPartnersListAsync,
    handleStatusChange,
    matchingText,
    handleIntegrationChange,
    handleOnTextFiledChange,
    handlePartnerFilterSearch,
    handleOnCloseClick,
    handlePartnerNameChange,
    hasMenuActions,
    menuActions,
    selectedPartnerCommand,
    toggleShowBulkCreateOrganizationModal,
    setToggleShowBulkCreateOrganizationModal,
    frozenColumn,
  } = usePartnerListService(
    selected,
    setSelected,
    names,
    setFilterdState,
    setSearchState,
    setAdvanceClick,
    setForceUpdate,
    advanceClick,
    setSelectedColumn,
    setSortedData,
    setSelectStatus,
    setIntegrationTypeState,
    setSelectSalesOwner,
    setSearchByID,
    setFullTextSearch,
    setSearchFieldValue,
    setSearchTerm,
    setSelectedPartner,
    searchTerm
  );

  const fetchApiHandler = (
    MultiCheckBoxASelect,
    statusSelected,
    columnSelected,
    integrationState,
    salesOwner,
    searchID,
    SearchText,
    paginationObject,
    partnerName
  ) => {
    if (!fetching) {
      handleFetchPartnersListAsync(
        {
          pageIndex: paginationObject.pageIndex,
          pageSize: paginationObject.pageSize,
        },
        columnSelected,
        MultiCheckBoxASelect,
        integrationState,
        salesOwner,
        searchID,
        SearchText,
        statusSelected,
        partnerName
      );
    }
    matchingText(
      searchByID,
      fullTextSearch,
      selected,
      selectStatus,
      selectSalesOwner,
      setMatchText,
      integrationState
    );
  };

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm.length > 2) {
      handlePartnerFilterSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (partnersList.length > 0) {
      setSortedData(partnersList);
    }
  }, [partnersList]);

  useEffect(() => {
    const convertedResponse = partnersFilterList
      .filter(
        (item) =>
          item?.partnerName !== undefined && item?.partnerName !== null && item?.partnerName !== ''
      )
      .map((item) => ({
        id: item?.id,
        value: item?.partnerName,
        title: item?.partnerName,
      }));
    setPartnerNames(convertedResponse);
  }, [partnersFilterList]);

  useEffect(() => {
    if (!advanceClick) {
      setSortedData(partnersList);
      // setMatchText('');
      setSelected([]);
      setSelectStatus('');
      setSelectedColumn('');
      setSelectSalesOwner('');
      setIntegrationTypeState('');
      setSelectedPartner('');
      setSearchTerm('');
    }
  }, [advanceClick]);

  const handleOnResetButtonClick = () => {
    setResetClicked(true);
    setMatchText('');
    setSortedData([]);
    setSelected([]);
    setSelectStatus('');
    setSelectedPartner('');
    setSearchTerm('');
    setSelectedColumn('');
    setSelectSalesOwner('');
    setSearchByID('');
    setFullTextSearch('');
    setSearchFieldValue('');
    setIntegrationTypeState('');
    dispatch(resetPartnersListState());
    dispatch(resetPartnersListState({}));
    fetchApiHandler([], '', '', '', '', '', '', { pageIndex: 0, pageSize: 10 }, '');
  };

  useEffect(() => {
    fetchApiHandler(
      selected,
      selectStatus,
      selectedColumn,
      integrationTypeState,
      selectSalesOwner,
      searchByID,
      fullTextSearch,
      pagination,
      selectedPartner
    );
  }, [pagination]);

  const handleOnSearchClick = (e) => {
    setResetClicked(false);
    setMatchText('');
    e.preventDefault();

    setPagination({ pageIndex: 0, pageSize: 10 });
    fetchApiHandler(
      selected,
      selectStatus,
      selectedColumn,
      integrationTypeState,
      selectSalesOwner,
      searchByID,
      fullTextSearch,
      {
        pageIndex: 0,
        pageSize: 10,
      },
      selectedPartner
    );
  };

  useEffect(() => {
    dispatch(setPartnersPaginationState(pagination));
  }, [pagination]);

  const { partnerDetailsState } = useSelector((state) => state.application);

  return (
    <>
      <HeaderPageBlock>{t('partner_details_page.header.text')}</HeaderPageBlock>
      {/* {purgeExternalAccountCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {purgeExternalAccountCorsErrorMessage}
        </QboAlert>
      )} */}
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={handleOnResetButtonClick}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <QboTextField
            id="fullTextSearch"
            value={searchFieldValue}
            isWidthSetExplicit
            placeholder="ID/Company Name/Email"
            inputProps={{ maxLength: 225 }}
            onChange={(e) => {
              handleOnTextFiledChange(e);
            }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleOnAdvanceSearchClick();
              }}>
              {advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {advanceClick && (
            <div>
              <QboSortByButton
                forceUpdate={forceUpdate}
                searchState={searchState}
                handleCloseClick={handleOnCloseClick}
                isSearchEnable={!false}
                handleOnSearchChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                handleChange={handlePartnerNameChange}
                marginRightValue={2}
                marginTopValue={2}
                value={selectedPartner}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText="Partner Name:"
                menuItemList={partnerNames}
              />
              <QboSortByButton
                forceUpdate={forceUpdate}
                searchState={searchState}
                isAllSelected={isAllSelected}
                handleCloseClick={handleCloseClick}
                isSearchEnable={!false}
                handleOnSearchChange={handleOnSearchChange}
                isCheckBoxColumnFilter
                marginRightValue={2}
                marginTopValue={2}
                selected={selected}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText={selected.length > 0 ? 'Partner Type:' : 'Partner Type:  All'}
                handleCheckBoxChange={handleCheckBoxChange}
                menuListCheckboxItems={filterdState}
              />
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={selectStatus}
                buttonWidth={220}
                startAdornmentTextWidth={70}
                startAdornmentText="Status:"
                handleChange={handleStatusChange}
                menuItemList={statusMenuItems}
              />
              {/* <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={2}
                value={age}
                buttonWidth={220}
                startAdornmentTextWidth={70}
                startAdornmentText="Parent:"
                handleChange={handleChange}
                menuItemList={menuItemList}
              /> */}
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={2}
                value={selectSalesOwner}
                buttonWidth={220}
                startAdornmentTextWidth={140}
                startAdornmentText="Sales Owner:"
                handleChange={handleChange}
                menuItemList={salesOwnersList}
              />
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={2}
                value={integrationTypeState}
                buttonWidth={220}
                startAdornmentTextWidth={200}
                startAdornmentText="Integration Type:"
                handleChange={handleIntegrationChange}
                menuItemList={integrationTypes}
              />
              <div>
                <QboSortByButton
                  defaultValue="None"
                  marginTopValue={2}
                  marginRightValue={2}
                  value={selectedColumn}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Sort by:"
                  handleChange={handleOnSortByChange}
                  menuItemList={columnNames}
                />
              </div>
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <TemplatesSection />

      <QboCard noPadding isMainWrapper>
        <hr />
        <QboTypography
          sx={{
            marginLeft: 2,
            marginBottom: 3,
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
          }}>
          Partners List
        </QboTypography>
        {partnersList.length > 0 && statusCode ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
            }}>
            {!resetClicked && matchText}
          </QboTypography>
        ) : null}
        {(sortedData.length > 0 || fetching) && (
          <QboAdvanceTable
            enableColumnClickable={!false}
            columnsClickableList={clickableCoulmnList}
            isLoading={fetching}
            enableTopToolBar={false}
            enableSorting
            rows={sortedData}
            headers={BaseSetting.partnerListHeaders}
            rowsCount={paginationList?.TotalCount}
            pagination={pagination}
            setPagination={setPagination}
            frozenColumns={frozenColumn}
            renderRowActionMenuItems={menuActions}
            enableRowActions={hasMenuActions}
          />
        )}
        {!sortedData.length && !fetching && <NoStatusSection />}
      </QboCard>

      <BulkCreateOrganizationModal
        selectedPartner={selectedPartnerCommand}
        title={t('partner_list_page.bulk_create_organization.title')}
        showModal={toggleShowBulkCreateOrganizationModal}
        closeModal={() => setToggleShowBulkCreateOrganizationModal(false)}>
        <BulkCreateOrganizationConfirmation selectedPartner={selectedPartnerCommand} />
      </BulkCreateOrganizationModal>
    </>
  );
}
